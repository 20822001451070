<template>
    <div class="container review-list" ref="container">

        <AReview
            :artistData="artistData"
            :reviewItemData="reviewItemData"
            :reviewData="reviewData"
            :reviewCountData="reviewCountData"
        />

        <div class="ui-border-line ui-h-0 ui-mt-4" />

        <div class="ui-border-line ui-h-1 ui-mt-3 ui-mb-3" />

        <ul class="review-list--sorting">
            <li
                class="sorting--item"
                :class="{
                    'sorting--item--active': query.sort === 'best',
                }"
                @click="() => changeSort('best')"
            >
                베스트순
            </li>
            <li
                class="sorting--item"
                :class="{
                    'sorting--item--active': query.sort === 'date',
                }"
                @click="() => changeSort('date')"
            >
                최신순
            </li>
            <li
                class="sorting--item"
                :class="{
                    'sorting--item--active': query.sort === 'star_desc',
                }"
                @click="() => changeSort('star_desc')"
            >
                별점높은순
            </li>
            <li
                class="sorting--item"
                :class="{
                    'sorting--item--active': query.sort === 'star_asc',
                }"
                @click="() => changeSort('star_asc')"
            >
                별점낮은순
            </li>
        </ul>

        <ul v-if="init && reviewData.length" class="review-list--container">
            <template v-for="(row, key) in reviewData">
                <li :key="key" class="review-list--item">
                    <div class="item--profile">
                        <div class="item--profile--thumbnail ui-bg-img" :style="itemStyles(row)" />

                        <div class="item--profile--info">
                            <StarRating class="item--rating" :size="18" :gutter="6" :value="getAvgPoint(row)" />
                            <div class="item--profile--text">
                                <div class="item--profile--name">{{ maskName(row.useridx > 0 ? row.userNickName : row.userName) }}</div>
                                <div class="item--create-date">{{ moment(row.createdDate * 1000).format('YY.MM.DD') }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="item--detail" v-if="row.detail">{{ row.detail }}</div>
                    <div class="item--help">
                        <button
                            class="item--help--btn"
                            :class="{
                                'item--help--btn--active': isGoodReview(row),
                            }"
                            @click="() => toggleGoodReview(row)"
                        >
                            도움이 돼요!
                        </button>
                        <span class="item--help--text">{{ row.good_count || 0 }}명에게 도움이 됐어요</span>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import StarRating from '@/components/common/StarRating.vue'
import AReview from '@/components/artist/AReview.vue'

type ReviewQuerySort = 'best' | 'date' | 'star_desc' | 'star_asc'

export default {
    name: 'ReviewList',
    components: {
        StarRating,
        AReview,
    },
    data() {
        const sort: ReviewQuerySort = 'best'

        return {
            init: false,
            artistData: {},
            reviewData: [],
            reviewItemData: [],
            reviewCountData: [],
            query: {
                sort,
            },
            offset: {
                reviewData: 0,
            },
            que: {
                reviewData: false,
                goodReview: false,
            },
        }
    },
    computed: {
        userData() {
            return this.$store?.state?.user?.userData
        },
        useridx() {
            return Number(this.userData?.useridx) || null
        },
    },
    created() {
        this.getReviewData()
    },
    mounted() {
        this.$refs.container.addEventListener('scroll', this.getReviewDataScroll, false)
        this.artistData = JSON.parse(sessionStorage.getItem('artistData') || '{}')
        this.reviewItemData = JSON.parse(sessionStorage.getItem('reviewItemData') || '[]')
        this.$store.commit('setGnbTitle', `리뷰 (${this.artistData.reviewCount || 0})`)
    },
    beforeDestroy() {
        this.$refs.container.removeEventListener('scroll', this.getReviewDataScroll, false)
        this.$store.commit('setGnbTitle', null)
    },
    methods: {
        maskName(value = '') {
            if (value && typeof value === 'string') {
                const arr = value.split('')

                if (arr[1]) {
                    arr[1] = '*'
                }

                return arr.join('')
            }
            return value
        },
        getReviewData(isInit = false) {
            if (this.que.reviewData) return
            this.que.reviewData = true

            if (isInit) {
                this.reviewData = []
                this.offset.reviewData = 0
            }

            const req = {
                method: 'get',
                url: `/artist/review/${this.$route.params.artistidx}/${this.offset.reviewData}/20`,
                params: this.query,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let reviewData = data.response.reviewData || []
                        let reviewItemData = data.response.reviewItemData || []
                        const reviewCountData = data.response.reviewCountData || []

                        this.reviewItemData = reviewItemData
                        this.reviewCountData = reviewCountData

                        this.reviewData = this.reviewData.concat(reviewData)
                        this.offset.reviewData += reviewData.length
                    } else if (resultData.code == -1) {
                        this.$router.push('/logout')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
                .then(() => {
                    this.init = true
                    setTimeout(() => {
                        this.que.reviewData = false
                    }, 500)
                })
        },
        toggleGoodReview(row) {
            if (this.que.goodReview) return
            this.que.goodReview = true

            const method = this.isGoodReview(row) ? 'delete' : 'post'

            const req = {
                method,
                url: `/artist/review/${row.uid}/good`,
            }

            this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        let goodCount = Number(row?.good_count) || 0

                        switch (method) {
                            case 'post': {
                                goodCount++
                                row.goodUserIdx = this.useridx
                                break
                            }

                            case 'delete': {
                                goodCount--
                                row.goodUserIdx = null
                                break
                            }
                        }

                        row.good_count = Math.max(0, goodCount)
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
                .then(() => {
                    this.que.goodReview = false
                })
        },
        getReviewDataScroll() {
            if (['ArtistReviewList'].indexOf(this.$route.name) === -1) return

            let scrollTop = this.$refs.container.scrollTop
            let scrollHeight = this.$refs.container.scrollHeight

            if (scrollHeight <= scrollTop + window.innerHeight) {
                this.getReviewData()
            }
        },
        changeSort(sort: ReviewQuerySort) {
            if (this.que.reviewData) return
            if (this.query.sort !== sort) {
                this.query.sort = sort
                this.getReviewData(true)
            }
        },
        itemStyles(row) {
            let userImgUrl = this.$lib.imgUrl('img/user/no-profile.png')

            if (row.userImgUrl) {
                userImgUrl = this.$lib.cdnUrl(row.userImgUrl)
            }

            return {
                backgroundImage: `url(${userImgUrl})`,
            }
        },
        isGoodReview(row) {
            const goodUserIdx = Number(row?.goodUserIdx) || null

            if (!this.useridx || !goodUserIdx) {
                return false
            }

            return this.useridx === goodUserIdx
        },
        getAvgPoint(row = {}) {
            const keys = Object.keys(row).filter(key => /^pointCount\d?$/.test(key))
            const avg = keys.reduce((total, key) => total + Number(row[key]), 0) / keys.length
            return avg
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.review-list {
    .review-list--sorting {
        display: flex;
        font-size: 2.1rem;
        line-height: 30px;
        margin-top: 4rem;

        .sorting--item {
            margin-right: 25px;
            color: #959595;
            font-weight: 500;
            cursor: pointer;

            &.sorting--item--active {
                color: #000;
                font-weight: 600;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .review-list--container {
        margin-top: 15px;

        .review-list--item {
            padding: 30px 0;
            .item--profile {
                display: flex;

                .item--profile--thumbnail {
                    width: 50px;
                    height: 50px;
                    margin-right: 12px;
                    border-radius: 50%;
                    overflow: hidden;
                }

                .item--profile--info {
                    flex: 1;
                    overflow: hidden;

                    .item--rating {
                        margin-bottom: 8px;
                    }

                    .item--profile--text {
                        display: flex;
                        font-size: 16px;
                        line-height: 24px;
                        color: #909090;

                        .item--profile--name {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .item--create-date {
                            margin-left: 22px;
                        }
                    }
                }
            }

            .item--detail {
                margin-top: 24px;
                font-size: 1.9rem;
                line-height: 30px;
                color: #000;
                white-space: pre-line;
                word-break: keep-all;
            }

            .item--help {
                margin-top: 18px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #909090;

                .item--help--btn {
                    outline: none;
                    cursor: pointer;
                    border-radius: 7px;
                    border: 1px solid $color-deepLavender;
                    background: #fff;
                    color: $color-deepLavender;
                    padding: 0 15px;
                    height: 35px;
                    line-height: 35px;
                    box-sizing: border-box;

                    &.item--help--btn--active {
                        border: 0;
                        background: $color-deepLavender;
                        color: #fff;
                    }
                }

                .item--help--text {
                    margin-left: 17.5px;
                }
            }
        }
    }
}
</style>
